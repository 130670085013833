@charset "UTF-8";
/**
*
* Main Stylesheet For Visual Style Guide
*
**/
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* web font */
#menu .menuContentsWrap > #menuLst li .menuTxtSub, #header > #header_page_lead .page_lead_en {
  font-family: 'Playfair Display', serif;
  font-style: italic; }

#section_book .contact_type .contact_type_inner > a > address, #footer #footerInner .footerTxt span, #footer #footerInner .copy {
  font-family: 'Playfair Display', serif;
  font-style: normal; }

/* hiragino mincho */
body {
  font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", 'NotoSerifCJKjp-Regular', serif;
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifCJKjp-Regular';
  src: url(/assets/font/NotoSerifCJKjp-Regular.ttf);
  src: url(/assets/font/NotoSerifCJKjp-Regular.woff); }

@font-face {
  font-family: 'adiNeue-Bold';
  src: url(/assets/fonts/adiNeue-Bold.otf); }

/*

easing

*/
/* LAYOUT */
/*

下敷き

*/
/*
カバー
$animation_type: true 左からみぎへ　
$animation_type: false 上から下へ
*/
/*

other

*/
@-webkit-keyframes volumeLineMotionShort {
  0% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  50% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  100% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); } }

@-moz-keyframes volumeLineMotionShort {
  0% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  50% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  100% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); } }

@-ms-keyframes volumeLineMotionShort {
  0% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  50% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  100% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); } }

@keyframes volumeLineMotionShort {
  0% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  50% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  100% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); } }

@-webkit-keyframes volumeLineMotionMid {
  0% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  50% {
    -webkit-transform: scaleY(0.7);
    -moz-transform: scaleY(0.7);
    -ms-transform: scaleY(0.7);
    transform: scaleY(0.7); }
  100% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); } }

@-moz-keyframes volumeLineMotionMid {
  0% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  50% {
    -webkit-transform: scaleY(0.7);
    -moz-transform: scaleY(0.7);
    -ms-transform: scaleY(0.7);
    transform: scaleY(0.7); }
  100% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); } }

@-ms-keyframes volumeLineMotionMid {
  0% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  50% {
    -webkit-transform: scaleY(0.7);
    -moz-transform: scaleY(0.7);
    -ms-transform: scaleY(0.7);
    transform: scaleY(0.7); }
  100% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); } }

@keyframes volumeLineMotionMid {
  0% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); }
  50% {
    -webkit-transform: scaleY(0.7);
    -moz-transform: scaleY(0.7);
    -ms-transform: scaleY(0.7);
    transform: scaleY(0.7); }
  100% {
    -webkit-transform: scaleY(0.3);
    -moz-transform: scaleY(0.3);
    -ms-transform: scaleY(0.3);
    transform: scaleY(0.3); } }

@-webkit-keyframes volumeLineMotionLong {
  0% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  50% {
    -webkit-transform: scaleY(0.9);
    -moz-transform: scaleY(0.9);
    -ms-transform: scaleY(0.9);
    transform: scaleY(0.9); }
  100% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); } }

@-moz-keyframes volumeLineMotionLong {
  0% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  50% {
    -webkit-transform: scaleY(0.9);
    -moz-transform: scaleY(0.9);
    -ms-transform: scaleY(0.9);
    transform: scaleY(0.9); }
  100% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); } }

@-ms-keyframes volumeLineMotionLong {
  0% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  50% {
    -webkit-transform: scaleY(0.9);
    -moz-transform: scaleY(0.9);
    -ms-transform: scaleY(0.9);
    transform: scaleY(0.9); }
  100% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); } }

@keyframes volumeLineMotionLong {
  0% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); }
  50% {
    -webkit-transform: scaleY(0.9);
    -moz-transform: scaleY(0.9);
    -ms-transform: scaleY(0.9);
    transform: scaleY(0.9); }
  100% {
    -webkit-transform: scaleY(0.5);
    -moz-transform: scaleY(0.5);
    -ms-transform: scaleY(0.5);
    transform: scaleY(0.5); } }

@-webkit-keyframes overlayYAnimation {
  0% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0); }
  50% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1; }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 0; } }

@-moz-keyframes overlayYAnimation {
  0% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0); }
  50% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1; }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 0; } }

@-ms-keyframes overlayYAnimation {
  0% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0); }
  50% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1; }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 0; } }

@keyframes overlayYAnimation {
  0% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0); }
  50% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1; }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 0; } }

/*

下層 mv zoom animation layer

*/
/*

menu

*/
@-webkit-keyframes navTriggerHoverAnimation {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  60% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-moz-keyframes navTriggerHoverAnimation {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  60% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-ms-keyframes navTriggerHoverAnimation {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  60% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@keyframes navTriggerHoverAnimation {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  60% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

/*

menu trigger click

*/
@-webkit-keyframes navTriggerHoverAnimation_on {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); } }

@-moz-keyframes navTriggerHoverAnimation_on {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); } }

@-ms-keyframes navTriggerHoverAnimation_on {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); } }

@keyframes navTriggerHoverAnimation_on {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); } }

/*

Top

*/
@-webkit-keyframes blurani {
  0% {
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px); }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px); } }

@-moz-keyframes blurani {
  0% {
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px); }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px); } }

@-ms-keyframes blurani {
  0% {
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px); }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px); } }

@keyframes blurani {
  0% {
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -ms-filter: blur(50px);
    filter: blur(50px); }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px); } }

/* news icon loop */
@-webkit-keyframes newsVarLoopAnimation {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  25% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  35% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  45% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  65% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-moz-keyframes newsVarLoopAnimation {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  55% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  75% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-ms-keyframes newsVarLoopAnimation {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  55% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  75% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@keyframes newsVarLoopAnimation {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  30% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  45% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  55% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

/*top newsLst　icon motion*/
@-webkit-keyframes newsLstIconAnimation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes newsLstIconAnimation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes newsLstIconAnimation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes newsLstIconAnimation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-2px); }
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

/*フッターページトップへ*/
@keyframes toPageTopIconAnimation {
  0% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1); }
  50% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0); }
  51% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scaleY(0);
    -moz-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0); }
  100% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scaleY(1);
    -moz-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1); } }

/*

meun close icon

*/
@-webkit-keyframes navCloseIconAnimationXStart {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-moz-keyframes navCloseIconAnimationXStart {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-ms-keyframes navCloseIconAnimationXStart {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@keyframes navCloseIconAnimationXStart {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-webkit-keyframes navCloseIconHoverAnimationX {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  60% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-moz-keyframes navCloseIconHoverAnimationX {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  60% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-ms-keyframes navCloseIconHoverAnimationX {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  60% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@keyframes navCloseIconHoverAnimationX {
  0% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  40% {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
    transform-origin: left;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  60% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-webkit-keyframes navCloseIconAnimationYStart {
  0% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); } }

@-moz-keyframes navCloseIconAnimationYStart {
  0% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); } }

@-ms-keyframes navCloseIconAnimationYStart {
  0% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); } }

@keyframes navCloseIconAnimationYStart {
  0% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); } }

@-webkit-keyframes navCloseIconHoverAnimationY {
  0% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  51% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); } }

@-moz-keyframes navCloseIconHoverAnimationY {
  0% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  51% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); } }

@-ms-keyframes navCloseIconHoverAnimationY {
  0% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  51% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); } }

@keyframes navCloseIconHoverAnimationY {
  0% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); }
  50% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px); }
  51% {
    -webkit-transform-origin: bottom;
    -moz-transform-origin: bottom;
    -ms-transform-origin: bottom;
    -o-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    transform: translateY(-30px); }
  100% {
    -webkit-transform-origin: top;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    transform: translateY(0px); } }

/*

ヘッダーロゴ

*/
@-webkit-keyframes headerLogoAnima {
  0% {
    -webkit-filter: blur(6px);
    -moz-filter: blur(6px);
    -ms-filter: blur(6px);
    filter: blur(6px);
    opacity: .3; }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-moz-keyframes headerLogoAnima {
  0% {
    -webkit-filter: blur(6px);
    -moz-filter: blur(6px);
    -ms-filter: blur(6px);
    filter: blur(6px);
    opacity: .3; }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@-ms-keyframes headerLogoAnima {
  0% {
    -webkit-filter: blur(6px);
    -moz-filter: blur(6px);
    -ms-filter: blur(6px);
    filter: blur(6px);
    opacity: .3; }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

@keyframes headerLogoAnima {
  0% {
    -webkit-filter: blur(6px);
    -moz-filter: blur(6px);
    -ms-filter: blur(6px);
    filter: blur(6px);
    opacity: .3; }
  100% {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
    opacity: 1; } }

/*

menu trigger start

*/
@-webkit-keyframes navTriggerAnimation_start {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-moz-keyframes navTriggerAnimation_start {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@-ms-keyframes navTriggerAnimation_start {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

@keyframes navTriggerAnimation_start {
  0% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0); }
  100% {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/*------------------------------------*    $CONTENTS
\*------------------------------------*/
/**
 * STYLE GUIDE VARIABLES------------------Declarations of Sass variables
 * -----Typography
 * -----Colors
 * -----Textfield
 * -----Switch
 * -----Spinner
 * -----Radio
 * -----Menu
 * -----List
 * -----Layout
 * -----Icon toggles
 * -----Footer
 * -----Column
 * -----Checkbox
 * -----Card
 * -----Button
 * -----Animation
 * -----Progress
 * -----Badge
 * -----Shadows
 * -----Grid
 * -----Data table
 * -----Dialog
 * -----Snackbar
 * -----Tooltip
 * -----Chip
 *
 * Even though all variables have the `!default` directive, most of them
 * should not be changed as they are dependent one another. This can cause
 * visual distortions (like alignment issues) that are hard to track down
 * and fix.
 */
/* ==========  TYPOGRAPHY  ========== */
/* We're splitting fonts into "preferred" and "performance" in order to optimize
   page loading. For important text, such as the body, we want it to load
   immediately and not wait for the web font load, whereas for other sections,
   such as headers and titles, we're OK with things taking a bit longer to load.
   We do have some optional classes and parameters in the mixins, in case you
   definitely want to make sure you're using the preferred font and don't mind
   the performance hit.
   We should be able to improve on this once CSS Font Loading L3 becomes more
   widely available.
*/
/* web font */
#menu .menuContentsWrap > #menuLst li .menuTxtSub, #header > #header_page_lead .page_lead_en {
  font-family: 'Playfair Display', serif;
  font-style: italic; }

#section_book .contact_type .contact_type_inner > a > address, #footer #footerInner .footerTxt span, #footer #footerInner .copy {
  font-family: 'Playfair Display', serif;
  font-style: normal; }

/* hiragino mincho */
body {
  font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", 'NotoSerifCJKjp-Regular', serif;
  font-style: normal; }

@font-face {
  font-family: 'NotoSerifCJKjp-Regular';
  src: url(/assets/font/NotoSerifCJKjp-Regular.ttf);
  src: url(/assets/font/NotoSerifCJKjp-Regular.woff); }

@font-face {
  font-family: 'adiNeue-Bold';
  src: url(/assets/fonts/adiNeue-Bold.otf); }

/*

easing

*/
/* LAYOUT */
/*
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 */
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
html {
  font-size: 1em;
  line-height: 1.4; }

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
  background: #b3d4fc;
  text-shadow: none; }

/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/*
 * Remove default fieldset styles.
 */
fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

/*
 * Allow only vertical resizing of textareas.
 */
textarea {
  resize: vertical; }

/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide visually and from screen readers:
 */
.hidden {
  display: none !important; }

/*
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*
 * Hide visually and from screen readers, but maintain layout
 */
.invisible {
  visibility: hidden; }

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */ }

.clearfix:after {
  clear: both; }

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */
@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */ }

@media print, (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */ }

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   http://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */
@media print {
  *,
  *:before,
  *:after,
  *:first-letter {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* Remove the unwanted box around FAB buttons */
/* More info: http://goo.gl/IPwKi */
a, .mdl-accordion, .mdl-button, .mdl-card, .mdl-checkbox, .mdl-dropdown-menu,
.mdl-icon-toggle, .mdl-item, .mdl-radio, .mdl-slider, .mdl-switch, .mdl-tabs__tab {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

/*
 * Make html take up the entire screen
 * Then set touch-action to avoid touch delay on mobile IE
 */
html {
  width: 100%;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

html,
body,
button {
  /* フォントレンダリング設定: 1x解像度ではsubpixel、2x以上の解像度ではgrayscale */
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset; }

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  html,
  body,
  button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; } }

body {
  -webkit-text-size-adjust: 100%;
  font-feature-settings: "palt"; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::i-block-chrome, body {
    font-feature-settings: "pkna"; } }

/*
* Make body take up the entire screen
* Remove body margin so layout containers don't cause extra overflow.
*/
body {
  width: 100%;
  min-height: 100%;
  margin: 0; }

/*
 * Main display reset for IE support.
 * Source: http://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
 */
main {
  display: block; }

/*
* Apply no display to elements with the hidden attribute.
* IE 9 and 10 support.
*/
*[hidden] {
  display: none !important; }

/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.centered {
  text-align: center; }

.js-box img {
  height: 100%;
  object-fit: cover;
  width: 100%; }

body.loading {
  width: 100%;
  height: 100%;
  overflow: hidden; }

html {
  height: 100%; }

/* Disable ugly boxes around images in IE10 */
a img {
  border: 0px; }

*,
*::before,
*::after {
  box-sizing: border-box; }

*:focus {
  outline: none; }

::-moz-selection {
  background-color: #000;
  color: #fff; }

::selection {
  background-color: #000;
  color: #fff; }

a {
  color: #111; }

a.hovering {
  text-decoration: none; }

body:not(.scrolling) .main-content {
  -webkit-transition-duration: .5s !important;
  -moz-transition-duration: .5s !important;
  -ms-transition-duration: .5s !important;
  transition-duration: .5s !important; }

body {
  margin: 0;
  height: 100%; }

body * {
  letter-spacing: 0.1em; }

body:not(.page--home) .main-content {
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top; }

.main-content {
  position: relative;
  min-width: 1100px;
  width: 100%;
  height: auto;
  background: url(/assets/img/common/bg/type_thin.jpg);
  background-size: 100px 100px;
  background-repeat: repeat;
  background-color: #eee; }
  .main-content._on {
    -webkit-filter: blur(9px);
    -moz-filter: blur(9px);
    -ms-filter: blur(9px);
    filter: blur(9px);
    -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -ms-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

.page .main-content._on {
  -webkit-transform: scale(1.025);
  -moz-transform: scale(1.025);
  -ms-transform: scale(1.025);
  transform: scale(1.025); }

.main-content > #main-content_inner {
  max-width: 1600px;
  min-width: 1100px;
  width: 100%;
  margin: auto;
  position: relative; }

.block {
  width: 100%;
  padding: 0 4.42vw;
  position: relative; }

/*

common

*/
.hover_animation_cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #c38118;
  -webkit-transform-origin: left;
  -moz-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left;
  transform-origin: left;
  -webkit-transition-duration: 0.5s;
  -ms-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

/* ============

ie・edge

============ */
.ieEdge #menu._in .menu_close_layer {
  opacity: .5; }

/* ============

menu contents area

============ */
#menu {
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9000;
  overflow: hidden; }
  #menu .menu_close_layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9040;
    opacity: 0;
    -webkit-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    transition-timing-function: linear;
    background-color: #000; }
  #menu #menuLayer {
    width: 400px;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9050;
    overflow: hidden; }
    #menu #menuLayer .contents_underlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff; }
  #menu .menuInner {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 1;
    overflow: hidden; }
  #menu .contents_underlay {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -ms-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    opacity: .8;
    -webkit-transition-duration: 0.86s;
    -ms-transition-duration: 0.86s;
    -moz-transition-duration: 0.86s;
    -o-transition-duration: 0.86s;
    transition-duration: 0.86s;
    -webkit-transition-delay: 0s;
    -ms-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -moz-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    -ms-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }
  #menu._in .menuInner #menuClose {
    opacity: 1; }
    #menu._in .menuInner #menuClose > .trigger_bg {
      -webkit-transform: scaleY(1);
      -moz-transform: scaleY(1);
      -ms-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transition-duration: 1s;
      -ms-transition-duration: 1s;
      -moz-transition-duration: 1s;
      -o-transition-duration: 1s;
      transition-duration: 1s;
      -webkit-transition-delay: 0.8s;
      -ms-transition-delay: 0.8s;
      -moz-transition-delay: 0.8s;
      -o-transition-delay: 0.8s;
      transition-delay: 0.8s;
      -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      -moz-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      -ms-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }
  #menu._in .menuInner > .menuContentsWrap #menuLst li {
    transform: translateX(0px); }
    #menu._in .menuInner > .menuContentsWrap #menuLst li:nth-child(1) {
      opacity: 1;
      -webkit-transition-duration: 1.8s;
      -ms-transition-duration: 1.8s;
      -moz-transition-duration: 1.8s;
      -o-transition-duration: 1.8s;
      transition-duration: 1.8s;
      -webkit-transition-delay: 0.25s;
      -ms-transition-delay: 0.25s;
      -moz-transition-delay: 0.25s;
      -o-transition-delay: 0.25s;
      transition-delay: 0.25s;
      -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
    #menu._in .menuInner > .menuContentsWrap #menuLst li:nth-child(2) {
      opacity: 1;
      -webkit-transition-duration: 1.8s;
      -ms-transition-duration: 1.8s;
      -moz-transition-duration: 1.8s;
      -o-transition-duration: 1.8s;
      transition-duration: 1.8s;
      -webkit-transition-delay: 0.33s;
      -ms-transition-delay: 0.33s;
      -moz-transition-delay: 0.33s;
      -o-transition-delay: 0.33s;
      transition-delay: 0.33s;
      -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
    #menu._in .menuInner > .menuContentsWrap #menuLst li:nth-child(3) {
      opacity: 1;
      -webkit-transition-duration: 1.8s;
      -ms-transition-duration: 1.8s;
      -moz-transition-duration: 1.8s;
      -o-transition-duration: 1.8s;
      transition-duration: 1.8s;
      -webkit-transition-delay: 0.41s;
      -ms-transition-delay: 0.41s;
      -moz-transition-delay: 0.41s;
      -o-transition-delay: 0.41s;
      transition-delay: 0.41s;
      -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  #menu._in .menuInner > .menuContentsWrap #insta {
    opacity: 1;
    transform: translateX(0px);
    -webkit-transition-duration: 1.8s;
    -ms-transition-duration: 1.8s;
    -moz-transition-duration: 1.8s;
    -o-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-delay: 0.49s;
    -ms-transition-delay: 0.49s;
    -moz-transition-delay: 0.49s;
    -o-transition-delay: 0.49s;
    transition-delay: 0.49s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  #menu._in .menuInner > .menuContentsWrap #lang {
    opacity: 1;
    transform: translateX(0px);
    -webkit-transition-duration: 1.8s;
    -ms-transition-duration: 1.8s;
    -moz-transition-duration: 1.8s;
    -o-transition-duration: 1.8s;
    transition-duration: 1.8s;
    -webkit-transition-delay: 0.57s;
    -ms-transition-delay: 0.57s;
    -moz-transition-delay: 0.57s;
    -o-transition-delay: 0.57s;
    transition-delay: 0.57s;
    -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  #menu._in .menuInner > .menuContentsWrap #booking {
    opacity: 1;
    -webkit-transition-duration: 0.35s;
    -ms-transition-duration: 0.35s;
    -moz-transition-duration: 0.35s;
    -o-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-delay: 1s;
    -ms-transition-delay: 1s;
    -moz-transition-delay: 1s;
    -o-transition-delay: 1s;
    transition-delay: 1s;
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    -ms-transition-timing-function: linear;
    transition-timing-function: linear; }
    #menu._in .menuInner > .menuContentsWrap #booking .def_cover {
      -webkit-transition-duration: 1.01s;
      -ms-transition-duration: 1.01s;
      -moz-transition-duration: 1.01s;
      -o-transition-duration: 1.01s;
      transition-duration: 1.01s;
      -webkit-transition-delay: 1.16s;
      -ms-transition-delay: 1.16s;
      -moz-transition-delay: 1.16s;
      -o-transition-delay: 1.16s;
      transition-delay: 1.16s;
      -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1); }
  #menu._in .contents_underlay {
    opacity: 1;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1); }
  #menu #menuClose._on a > span i > .line_close_icon:nth-child(1) {
    -webkit-animation-name: navCloseIconAnimationXStart;
    -ms-animation-name: navCloseIconAnimationXStart;
    -moz-animation-name: navCloseIconAnimationXStart;
    -o-animation-name: navCloseIconAnimationXStart;
    animation-name: navCloseIconAnimationXStart;
    -webkit-animation-duration: 0.8s;
    -ms-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
    -o-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-delay: 0.1s;
    -ms-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -o-animation-delay: 0.1s;
    animation-delay: 0.1s;
    -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    -ms-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both; }
  #menu #menuClose._on a > span i > .line_close_icon:nth-child(2) {
    -webkit-animation-name: navCloseIconAnimationYStart;
    -ms-animation-name: navCloseIconAnimationYStart;
    -moz-animation-name: navCloseIconAnimationYStart;
    -o-animation-name: navCloseIconAnimationYStart;
    animation-name: navCloseIconAnimationYStart;
    -webkit-animation-duration: 0.8s;
    -ms-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
    -o-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-delay: 0.25s;
    -ms-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
    -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    -ms-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both; }
  #menu #menuClose._on a > span i > .line_close_icon:nth-child(3) {
    -webkit-animation-name: navCloseIconAnimationYStart;
    -ms-animation-name: navCloseIconAnimationYStart;
    -moz-animation-name: navCloseIconAnimationYStart;
    -o-animation-name: navCloseIconAnimationYStart;
    animation-name: navCloseIconAnimationYStart;
    -webkit-animation-duration: 0.8s;
    -ms-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
    -o-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-delay: 0.38s;
    -ms-animation-delay: 0.38s;
    -moz-animation-delay: 0.38s;
    -o-animation-delay: 0.38s;
    animation-delay: 0.38s;
    -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    -ms-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both; }
  #menu .menuContentsWrap > #menuLst li, #menu .menuContentsWrap > #insta, #menu .menuContentsWrap > #lang {
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px); }
  #menu .menuInner > .menuContentsWrap {
    padding-top: 24vh;
    padding-left: 60px; }
  #menu .menuContentsWrap > #menuLst li {
    margin: 0 0 4.8vh;
    opacity: 0; }
    #menu .menuContentsWrap > #menuLst li a {
      display: inline-block;
      text-decoration: none; }
    #menu .menuContentsWrap > #menuLst li span {
      display: block; }
    #menu .menuContentsWrap > #menuLst li .menuTxt {
      font-size: 20px; }
      #menu .menuContentsWrap > #menuLst li .menuTxt .menuTxtFront {
        font-size: 20px;
        line-height: 1.1em;
        position: absolute;
        z-index: 1;
        opacity: .9;
        -webkit-transform-origin: left;
        -moz-transform-origin: left;
        -ms-transform-origin: left;
        -o-transform-origin: left;
        transform-origin: left;
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -ms-clip: rect(0px, 180px, 22px, 0px);
        -moz-clip: rect(0px, 180px, 22px, 0px);
        -webkit-clip: rect(0px, 180px, 22px, 0px);
        clip: rect(0px, 180px, 22px, 0px); }
      #menu .menuContentsWrap > #menuLst li .menuTxt .menuTxtBack {
        font-size: 20px;
        line-height: 1.1em;
        position: relative;
        top: 0;
        z-index: 0;
        opacity: .5; }
    #menu .menuContentsWrap > #menuLst li .menuTxtSub {
      opacity: .5;
      font-size: 12px;
      margin: 1.34vh 0 0; }
    #menu .menuContentsWrap > #menuLst li:last-child {
      margin: 0; }
  #menu .menuContentsWrap > #insta {
    margin: 7.34vh 0 0;
    opacity: 0; }
    #menu .menuContentsWrap > #insta a {
      display: inline-block;
      text-decoration: none;
      -webkit-transition-duration: .5s;
      -moz-transition-duration: .5s;
      -ms-transition-duration: .5s;
      transition-duration: .5s; }
      #menu .menuContentsWrap > #insta a:hover {
        opacity: .5; }
    #menu .menuContentsWrap > #insta span {
      display: inline-block; }
    #menu .menuContentsWrap > #insta .instaIcon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      position: relative;
      background-repeat: no-repeat; }
      #menu .menuContentsWrap > #insta .instaIcon .insta_icon {
        position: absolute;
        top: 0; }
    #menu .menuContentsWrap > #insta .instaTxt {
      font-size: 15px;
      vertical-align: bottom; }
  #menu .menuContentsWrap > #lang {
    margin: 7.47vh 0 0;
    opacity: 0; }
    #menu .menuContentsWrap > #lang > li {
      font-size: 15px;
      opacity: .5;
      display: inline-block;
      margin-right: 25px; }
      #menu .menuContentsWrap > #lang > li a {
        display: inline-block;
        text-decoration: none; }
      #menu .menuContentsWrap > #lang > li._on {
        opacity: 1; }
      #menu .menuContentsWrap > #lang > li:last-child {
        margin-right: 0px; }
  #menu .menuContentsWrap > #booking {
    width: 100%;
    height: 110px;
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0; }
    #menu .menuContentsWrap > #booking a {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      z-index: 1;
      text-decoration: none; }
    #menu .menuContentsWrap > #booking .bookingTxt {
      width: 100%;
      height: 100%;
      margin: auto;
      display: table; }
      #menu .menuContentsWrap > #booking .bookingTxt > span {
        font-size: 16px;
        color: #fff;
        text-align: center;
        display: table-cell;
        vertical-align: middle; }
    #menu .menuContentsWrap > #booking .def_cover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      -webkit-transform-origin: left;
      -moz-transform-origin: left;
      -ms-transform-origin: left;
      -o-transform-origin: left;
      transform-origin: left;
      -webkit-transition-duration: 1.15s;
      -ms-transition-duration: 1.15s;
      -moz-transition-duration: 1.15s;
      -o-transition-duration: 1.15s;
      transition-duration: 1.15s;
      -webkit-transition-delay: 1s;
      -ms-transition-delay: 1s;
      -moz-transition-delay: 1s;
      -o-transition-delay: 1s;
      transition-delay: 1s;
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0); }
    #menu .menuContentsWrap > #booking .hover_animation_cover {
      -webkit-transform: scaleX(0.01);
      -moz-transform: scaleX(0.01);
      -ms-transform: scaleX(0.01);
      transform: scaleX(0.01); }
    #menu .menuContentsWrap > #booking:hover .hover_animation_cover {
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1); }
  #menu .menuInner > #menuClose {
    opacity: 0;
    width: 60px;
    height: 60px;
    position: absolute;
    overflow: hidden;
    top: 90px;
    right: 60px;
    z-index: 9100; }
    #menu .menuInner > #menuClose a {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    #menu .menuInner > #menuClose:hover > a > span i > span:nth-child(1) {
      -webkit-animation-name: navCloseIconHoverAnimationX;
      -ms-animation-name: navCloseIconHoverAnimationX;
      -moz-animation-name: navCloseIconHoverAnimationX;
      -o-animation-name: navCloseIconHoverAnimationX;
      animation-name: navCloseIconHoverAnimationX;
      -webkit-animation-duration: 0.8s;
      -ms-animation-duration: 0.8s;
      -moz-animation-duration: 0.8s;
      -o-animation-duration: 0.8s;
      animation-duration: 0.8s;
      -webkit-animation-delay: 0.1s;
      -ms-animation-delay: 0.1s;
      -moz-animation-delay: 0.1s;
      -o-animation-delay: 0.1s;
      animation-delay: 0.1s;
      -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-animation-iteration-count: 1;
      -ms-animation-iteration-count: 1;
      -moz-animation-iteration-count: 1;
      -o-animation-iteration-count: 1;
      animation-iteration-count: 1;
      -webkit-animation-direction: normal;
      -ms-animation-direction: normal;
      -moz-animation-direction: normal;
      -o-animation-direction: normal;
      animation-direction: normal;
      -webkit-animation-fill-mode: both;
      -ms-animation-fill-mode: both;
      -moz-animation-fill-mode: both;
      -o-animation-fill-mode: both;
      animation-fill-mode: both; }
    #menu .menuInner > #menuClose:hover > a > span i > span:nth-child(2) {
      -webkit-animation-name: navCloseIconHoverAnimationY;
      -ms-animation-name: navCloseIconHoverAnimationY;
      -moz-animation-name: navCloseIconHoverAnimationY;
      -o-animation-name: navCloseIconHoverAnimationY;
      animation-name: navCloseIconHoverAnimationY;
      -webkit-animation-duration: 0.8s;
      -ms-animation-duration: 0.8s;
      -moz-animation-duration: 0.8s;
      -o-animation-duration: 0.8s;
      animation-duration: 0.8s;
      -webkit-animation-delay: 0.25s;
      -ms-animation-delay: 0.25s;
      -moz-animation-delay: 0.25s;
      -o-animation-delay: 0.25s;
      animation-delay: 0.25s;
      -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-animation-iteration-count: 1;
      -ms-animation-iteration-count: 1;
      -moz-animation-iteration-count: 1;
      -o-animation-iteration-count: 1;
      animation-iteration-count: 1;
      -webkit-animation-direction: normal;
      -ms-animation-direction: normal;
      -moz-animation-direction: normal;
      -o-animation-direction: normal;
      animation-direction: normal;
      -webkit-animation-fill-mode: both;
      -ms-animation-fill-mode: both;
      -moz-animation-fill-mode: both;
      -o-animation-fill-mode: both;
      animation-fill-mode: both; }
    #menu .menuInner > #menuClose:hover > a > span i > span:nth-child(3) {
      -webkit-animation-name: navCloseIconHoverAnimationY;
      -ms-animation-name: navCloseIconHoverAnimationY;
      -moz-animation-name: navCloseIconHoverAnimationY;
      -o-animation-name: navCloseIconHoverAnimationY;
      animation-name: navCloseIconHoverAnimationY;
      -webkit-animation-duration: 0.8s;
      -ms-animation-duration: 0.8s;
      -moz-animation-duration: 0.8s;
      -o-animation-duration: 0.8s;
      animation-duration: 0.8s;
      -webkit-animation-delay: 0.38s;
      -ms-animation-delay: 0.38s;
      -moz-animation-delay: 0.38s;
      -o-animation-delay: 0.38s;
      animation-delay: 0.38s;
      -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-animation-iteration-count: 1;
      -ms-animation-iteration-count: 1;
      -moz-animation-iteration-count: 1;
      -o-animation-iteration-count: 1;
      animation-iteration-count: 1;
      -webkit-animation-direction: normal;
      -ms-animation-direction: normal;
      -moz-animation-direction: normal;
      -o-animation-direction: normal;
      animation-direction: normal;
      -webkit-animation-fill-mode: both;
      -ms-animation-fill-mode: both;
      -moz-animation-fill-mode: both;
      -o-animation-fill-mode: both;
      animation-fill-mode: both; }
    #menu .menuInner > #menuClose > a > span {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      overflow: hidden;
      display: inline-block; }
      #menu .menuInner > #menuClose > a > span i {
        width: 30px;
        height: 30px;
        display: inline-block;
        overflow: hidden;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg); }
        #menu .menuInner > #menuClose > a > span i > span:nth-child(1) {
          width: 100%;
          height: 1px;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background-color: #fff;
          -webkit-transform: scaleX(0);
          -moz-transform: scaleX(0);
          -ms-transform: scaleX(0);
          transform: scaleX(0); }
        #menu .menuInner > #menuClose > a > span i > span:nth-child(2) {
          width: 1px;
          height: 38%;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          background-color: #fff;
          -webkit-transform: translateY(-30px);
          -moz-transform: translateY(-30px);
          -ms-transform: translateY(-30px);
          transform: translateY(-30px); }
        #menu .menuInner > #menuClose > a > span i > span:nth-child(3) {
          width: 1px;
          height: 38%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          background-color: #fff;
          -webkit-transform: translateY(-30px);
          -moz-transform: translateY(-30px);
          -ms-transform: translateY(-30px);
          transform: translateY(-30px); }
    #menu .menuInner > #menuClose .trigger_bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      -webkit-transform-origin: top;
      -moz-transform-origin: top;
      -ms-transform-origin: top;
      -o-transform-origin: top;
      transform-origin: top;
      -webkit-transform: scaleY(0);
      -moz-transform: scaleY(0);
      -ms-transform: scaleY(0);
      transform: scaleY(0); }

/* ============

header
 ・page lead
 ・headerlogo
 ・menu trigger

============ */
#header {
  width: 100%;
  height: 280px;
  position: relative;
  padding: 0px 4.42vw 0px;
  margin: auto;
  opacity: 0;
  -webkit-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-transition-delay: 0s;
  -ms-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -moz-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  -ms-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }
  #header._on {
    opacity: 1; }

body:not(.smp):not(.ieEdge) #header #header_logo #header_logo_inner:hover span.logo_1 {
  -webkit-animation-name: headerLogoAnima;
  -ms-animation-name: headerLogoAnima;
  -moz-animation-name: headerLogoAnima;
  -o-animation-name: headerLogoAnima;
  animation-name: headerLogoAnima;
  -webkit-animation-duration: 0.88s;
  -ms-animation-duration: 0.88s;
  -moz-animation-duration: 0.88s;
  -o-animation-duration: 0.88s;
  animation-duration: 0.88s;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -moz-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -ms-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body:not(.smp):not(.ieEdge) #header #header_logo #header_logo_inner:hover span.logo_2 {
  -webkit-animation-name: headerLogoAnima;
  -ms-animation-name: headerLogoAnima;
  -moz-animation-name: headerLogoAnima;
  -o-animation-name: headerLogoAnima;
  animation-name: headerLogoAnima;
  -webkit-animation-duration: 0.72s;
  -ms-animation-duration: 0.72s;
  -moz-animation-duration: 0.72s;
  -o-animation-duration: 0.72s;
  animation-duration: 0.72s;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -moz-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -ms-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body:not(.smp):not(.ieEdge) #header #header_logo #header_logo_inner:hover span.logo_3 {
  -webkit-animation-name: headerLogoAnima;
  -ms-animation-name: headerLogoAnima;
  -moz-animation-name: headerLogoAnima;
  -o-animation-name: headerLogoAnima;
  animation-name: headerLogoAnima;
  -webkit-animation-duration: 0.78s;
  -ms-animation-duration: 0.78s;
  -moz-animation-duration: 0.78s;
  -o-animation-duration: 0.78s;
  animation-duration: 0.78s;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -moz-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -ms-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body:not(.smp):not(.ieEdge) #header #header_logo #header_logo_inner:hover span.logo_4 {
  -webkit-animation-name: headerLogoAnima;
  -ms-animation-name: headerLogoAnima;
  -moz-animation-name: headerLogoAnima;
  -o-animation-name: headerLogoAnima;
  animation-name: headerLogoAnima;
  -webkit-animation-duration: 0.88s;
  -ms-animation-duration: 0.88s;
  -moz-animation-duration: 0.88s;
  -o-animation-duration: 0.88s;
  animation-duration: 0.88s;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -moz-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -ms-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body:not(.smp):not(.ieEdge) #header #header_logo #header_logo_inner:hover span.logo_5 {
  -webkit-animation-name: headerLogoAnima;
  -ms-animation-name: headerLogoAnima;
  -moz-animation-name: headerLogoAnima;
  -o-animation-name: headerLogoAnima;
  animation-name: headerLogoAnima;
  -webkit-animation-duration: 0.92s;
  -ms-animation-duration: 0.92s;
  -moz-animation-duration: 0.92s;
  -o-animation-duration: 0.92s;
  animation-duration: 0.92s;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -moz-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -ms-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body:not(.smp):not(.ieEdge) #header #header_logo #header_logo_inner:hover span.logo_6 {
  -webkit-animation-name: headerLogoAnima;
  -ms-animation-name: headerLogoAnima;
  -moz-animation-name: headerLogoAnima;
  -o-animation-name: headerLogoAnima;
  animation-name: headerLogoAnima;
  -webkit-animation-duration: 0.8s;
  -ms-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  -o-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -moz-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  -ms-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

#header > #header_logo {
  width: 54px;
  height: 116px;
  position: absolute;
  top: 80px;
  left: 10px;
  right: 0px;
  margin: auto; }
  #header > #header_logo a {
    width: 100%;
    height: 100%;
    display: block; }
    #header > #header_logo a:hover {
      -webkit-transition-duration: 0.8s;
      -ms-transition-duration: 0.8s;
      -moz-transition-duration: 0.8s;
      -o-transition-duration: 0.8s;
      transition-duration: 0.8s;
      -webkit-transition-delay: 0s;
      -ms-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;
      -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      opacity: .8; }
  #header > #header_logo img {
    width: 100%;
    display: block; }
  #header > #header_logo #header_logo_inner {
    width: 54px;
    height: 116px;
    position: absolute;
    display: block;
    top: 0;
    left: 0; }
    #header > #header_logo #header_logo_inner span {
      fill: #000; }
      #header > #header_logo #header_logo_inner span.logo_1 {
        width: 21px;
        height: 25px;
        position: absolute;
        top: 8px;
        left: 6px; }
      #header > #header_logo #header_logo_inner span.logo_2 {
        width: 28px;
        height: 33px;
        position: absolute;
        top: 42px;
        left: 2px; }
      #header > #header_logo #header_logo_inner span.logo_3 {
        width: 33px;
        height: 32px;
        position: absolute;
        top: 85px;
        left: 0px; }
      #header > #header_logo #header_logo_inner span.logo_4 {
        width: 13px;
        height: 19px;
        position: absolute;
        top: -1px;
        left: 38px; }
      #header > #header_logo #header_logo_inner span.logo_5 {
        width: 10px;
        height: 11px;
        position: absolute;
        top: 19px;
        left: 40px; }
      #header > #header_logo #header_logo_inner span.logo_6 {
        width: 18px;
        height: 16px;
        position: absolute;
        top: 36px;
        left: 36px; }

#header > #header_page_lead {
  position: absolute;
  top: 100px;
  left: 60px; }
  #header > #header_page_lead .page_lead {
    font-size: 22px; }
  #header > #header_page_lead .page_lead_en {
    opacity: .5;
    font-size: 14px;
    margin: 12px 0 0;
    display: block; }
  #header > #header_page_lead .page_lead_detail {
    position: relative;
    font-size: 15px;
    left: 30px;
    top: 12px;
    line-height: 1.5; }
    #header > #header_page_lead .page_lead_detail:before {
      content: "";
      width: 16px;
      height: 2px;
      position: absolute;
      top: 7px;
      left: -30px;
      margin: auto;
      background: #000; }

/* ============

ieEdge mv部分

============ */
.ieEdge #mvArea_c_inner > .mvWrap .zoomLayer .mv,
.smp #mvArea_c_inner > .mvWrap .zoomLayer .mv {
  -webkit-filter: blur(0px) !important;
  -moz-filter: blur(0px) !important;
  -ms-filter: blur(0px) !important;
  filter: blur(0px) !important; }

.ieEdge #mvArea_r_inner > .mvWrap .zoomLayer .mv,
.smp #mvArea_r_inner > .mvWrap .zoomLayer .mv {
  -webkit-filter: blur(0px) !important;
  -moz-filter: blur(0px) !important;
  -ms-filter: blur(0px) !important;
  filter: blur(0px) !important; }

/* ============

menu trigger (icon)

============ */
#menuTrigger {
  width: 60px;
  height: 60px;
  position: absolute;
  overflow: hidden;
  top: 90px;
  right: 60px;
  z-index: 2000; }
  #menuTrigger a {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  #menuTrigger > a > span {
    width: 25px;
    height: 23px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    overflow: hidden; }
    #menuTrigger > a > span i {
      width: 25px;
      height: 23px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto; }
    #menuTrigger > a > span .line_top {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      background-color: #fff; }
    #menuTrigger > a > span .line_middle {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 49%;
      margin: auto;
      background-color: #fff; }
    #menuTrigger > a > span .line_bottom {
      width: 50%;
      height: 1px;
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: #fff; }
  #menuTrigger .trigger_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000; }
  #menuTrigger.non-hover {
    visibility: hidden; }
  #menuTrigger:hover .line_top {
    -webkit-animation-name: navTriggerHoverAnimation;
    -ms-animation-name: navTriggerHoverAnimation;
    -moz-animation-name: navTriggerHoverAnimation;
    -o-animation-name: navTriggerHoverAnimation;
    animation-name: navTriggerHoverAnimation;
    -webkit-animation-duration: 0.62s;
    -ms-animation-duration: 0.62s;
    -moz-animation-duration: 0.62s;
    -o-animation-duration: 0.62s;
    animation-duration: 0.62s;
    -webkit-animation-delay: 0s;
    -ms-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    -ms-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both; }
  #menuTrigger:hover .line_middle {
    -webkit-animation-name: navTriggerHoverAnimation;
    -ms-animation-name: navTriggerHoverAnimation;
    -moz-animation-name: navTriggerHoverAnimation;
    -o-animation-name: navTriggerHoverAnimation;
    animation-name: navTriggerHoverAnimation;
    -webkit-animation-duration: 0.62s;
    -ms-animation-duration: 0.62s;
    -moz-animation-duration: 0.62s;
    -o-animation-duration: 0.62s;
    animation-duration: 0.62s;
    -webkit-animation-delay: 0.1s;
    -ms-animation-delay: 0.1s;
    -moz-animation-delay: 0.1s;
    -o-animation-delay: 0.1s;
    animation-delay: 0.1s;
    -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    -ms-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both; }
  #menuTrigger:hover .line_bottom {
    -webkit-animation-name: navTriggerHoverAnimation;
    -ms-animation-name: navTriggerHoverAnimation;
    -moz-animation-name: navTriggerHoverAnimation;
    -o-animation-name: navTriggerHoverAnimation;
    animation-name: navTriggerHoverAnimation;
    -webkit-animation-duration: 0.62s;
    -ms-animation-duration: 0.62s;
    -moz-animation-duration: 0.62s;
    -o-animation-duration: 0.62s;
    animation-duration: 0.62s;
    -webkit-animation-delay: 0.2s;
    -ms-animation-delay: 0.2s;
    -moz-animation-delay: 0.2s;
    -o-animation-delay: 0.2s;
    animation-delay: 0.2s;
    -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-animation-iteration-count: 1;
    -ms-animation-iteration-count: 1;
    -moz-animation-iteration-count: 1;
    -o-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    -ms-animation-direction: normal;
    -moz-animation-direction: normal;
    -o-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both; }

/* ==============

mvArea_r

============== */
#mvArea_r {
  width: 100%;
  position: relative; }

#mvArea_r_inner {
  width: 100%;
  height: 540px;
  position: relative; }
  #mvArea_r_inner > .mvWrap {
    width: calc(100% - 60px);
    height: 540px;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    position: relative;
    margin-left: 60px; }
    #mvArea_r_inner > .mvWrap .contents_overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform-origin: left;
      -moz-transform-origin: left;
      -ms-transform-origin: left;
      transform-origin: left;
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      background-color: #ebebeb; }
    @media only screen and (max-width: 769px) {
      #mvArea_r_inner > .mvWrap {
        margin-left: 0px; } }
    #mvArea_r_inner > .mvWrap .mvWrapInner {
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
      position: relative;
      z-index: 100;
      visibility: hidden; }
    #mvArea_r_inner > .mvWrap .zoomLayer {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      display: block;
      transform: rotate(0.001deg); }
      #mvArea_r_inner > .mvWrap .zoomLayer .mv {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: relative;
        background-size: cover !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }
  #mvArea_r_inner > .link {
    width: 130px;
    height: 180px;
    position: absolute;
    top: 0;
    left: 20px;
    bottom: 0;
    margin: auto;
    z-index: 100;
    -ms-transform: translate(-20px, 0);
    -webkit-transform: translate(-20px, 0);
    transform: translate(-20px, 0);
    opacity: 0; }
    #mvArea_r_inner > .link .linkLst {
      width: 100%; }
      #mvArea_r_inner > .link .linkLst li {
        width: 100%;
        height: 90px;
        background-color: #000;
        position: relative;
        overflow: hidden; }
        #mvArea_r_inner > .link .linkLst li a {
          width: 100%;
          height: 100%;
          display: block;
          text-decoration: none; }
          #mvArea_r_inner > .link .linkLst li a > span {
            width: 100%;
            height: 100%;
            display: table; }
            #mvArea_r_inner > .link .linkLst li a > span span {
              width: 100%;
              height: 100%;
              text-align: center;
              font-size: 15px;
              color: #fff;
              display: table-cell;
              vertical-align: middle;
              position: relative;
              z-index: 1; }
        #mvArea_r_inner > .link .linkLst li:nth-child(1) .hover_animation_cover, #mvArea_r_inner > .link .linkLst li:nth-child(2) .hover_animation_cover, #mvArea_r_inner > .link .linkLst li:nth-child(3) .hover_animation_cover {
          -webkit-transform: scaleX(0.03);
          -moz-transform: scaleX(0.03);
          -ms-transform: scaleX(0.03);
          transform: scaleX(0.03); }
        #mvArea_r_inner > .link .linkLst li:nth-child(1):hover .hover_animation_cover, #mvArea_r_inner > .link .linkLst li:nth-child(2):hover .hover_animation_cover, #mvArea_r_inner > .link .linkLst li:nth-child(3):hover .hover_animation_cover {
          -webkit-transform: scaleX(1);
          -moz-transform: scaleX(1);
          -ms-transform: scaleX(1);
          transform: scaleX(1); }

/* ==============

    mvArea_c

============== */
#mvArea_c {
  width: 100%;
  position: relative;
  display: inline-table;
  overflow: hidden; }

#mvArea_c_inner {
  width: 100%;
  height: 480px;
  position: relative; }
  #mvArea_c_inner > .mvWrap {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: block;
    overflow: hidden;
    position: relative; }
    #mvArea_c_inner > .mvWrap .mvWrapInner {
      width: 100%;
      height: 100%;
      display: block;
      overflow: hidden;
      position: relative;
      z-index: 100;
      visibility: hidden; }
    #mvArea_c_inner > .mvWrap .contents_overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform-origin: left;
      -moz-transform-origin: left;
      -ms-transform-origin: left;
      transform-origin: left;
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      background-color: #ebebeb; }
    #mvArea_c_inner > .mvWrap .zoomLayer {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      display: block;
      transform: rotate(0.001deg); }
      #mvArea_c_inner > .mvWrap .zoomLayer .mv {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        position: relative;
        background-size: cover !important;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }

/* ==============

     ご予約

============== */
#section_book {
  width: 100%;
  min-height: 500px;
  position: relative; }
  #section_book.inview {
    opacity: 0;
    -webkit-transition: all 0.7s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: all 0.7s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: all 0.7s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    transform: translateY(20px);
    backface-visibility: hidden; }
  #section_book.inview.in {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    backface-visibility: hidden; }
    #section_book.inview.in #section_book_inner > div .booking_r .booking_r_inner .booking_ttl .booking_ttl_icon {
      -webkit-transition-duration: 0.8s;
      -ms-transition-duration: 0.8s;
      -moz-transition-duration: 0.8s;
      -o-transition-duration: 0.8s;
      transition-duration: 0.8s;
      -webkit-transition-delay: 0.8s;
      -ms-transition-delay: 0.8s;
      -moz-transition-delay: 0.8s;
      -o-transition-delay: 0.8s;
      transition-delay: 0.8s;
      -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -moz-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -ms-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1); }
  #section_book #section_book_inner {
    width: 100%;
    height: 100%; }
    #section_book #section_book_inner > div {
      width: 100%;
      height: 100%;
      display: table; }
      #section_book #section_book_inner > div .booking_l {
        width: 50%;
        height: 100%;
        display: table-cell;
        vertical-align: top;
        position: absolute; }
        #section_book #section_book_inner > div .booking_l .booking_l_img {
          width: 100%;
          height: 100%;
          display: block;
          background: url(/assets/img/common/reservation_img.jpg);
          background-size: cover !important;
          background-position: center center !important;
          background-repeat: no-repeat !important; }
      #section_book #section_book_inner > div .booking_r {
        width: 50%;
        height: 100%;
        display: table-cell;
        vertical-align: top;
        background-color: #000; }
        #section_book #section_book_inner > div .booking_r .booking_r_inner {
          width: 100%;
          height: 100%;
          padding: 72px 78px 115px;
          position: relative;
          box-sizing: border-box; }
          #section_book #section_book_inner > div .booking_r .booking_r_inner .booking_ttl {
            position: relative;
            left: 0px;
            padding: 0px 0px 0 60px;
            font-size: 20px;
            color: #fff; }
            #section_book #section_book_inner > div .booking_r .booking_r_inner .booking_ttl .booking_ttl_icon {
              width: 40px;
              height: 4px;
              display: block;
              position: absolute;
              top: 0;
              left: 0px;
              bottom: 0;
              margin: auto;
              -webkit-transform-origin: left;
              -moz-transform-origin: left;
              -ms-transform-origin: left;
              transform-origin: left;
              -webkit-transform: scaleX(0);
              -moz-transform: scaleX(0);
              -ms-transform: scaleX(0);
              transform: scaleX(0);
              background-color: #c38118; }
          #section_book #section_book_inner > div .booking_r .booking_r_inner .booking_ttl_sub {
            margin: 35px 0 20px;
            font-size: 15px;
            color: #fff;
            line-height: 1.6; }
  #section_book .contact_type {
    width: 100%;
    position: relative;
    display: table; }
    #section_book .contact_type .contact_type_inner {
      width: 50%;
      position: relative;
      display: table-cell;
      vertical-align: top;
      background-color: #141414; }
      #section_book .contact_type .contact_type_inner:hover:hover {
        -webkit-transition-duration: 0.8s;
        -ms-transition-duration: 0.8s;
        -moz-transition-duration: 0.8s;
        -o-transition-duration: 0.8s;
        transition-duration: 0.8s;
        -webkit-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
        -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        opacity: .8; }
      #section_book .contact_type .contact_type_inner a {
        width: 100%;
        height: 100%;
        display: block;
        padding: 35px 0 35px;
        box-sizing: border-box; }
      #section_book .contact_type .contact_type_inner.forSp {
        display: none; }
      #section_book .contact_type .contact_type_inner.forPc {
        cursor: default;
        pointer-events: none; }
      #section_book .contact_type .contact_type_inner > a > span {
        color: #4c4c4c;
        text-align: center;
        font-size: 16px;
        display: block; }
      #section_book .contact_type .contact_type_inner > a > p {
        color: #fff;
        margin: 20px 0 0;
        text-align: center;
        font-size: 15px; }
      #section_book .contact_type .contact_type_inner > a > address {
        color: #fff;
        margin: 15px 0 0;
        font-size: 20px;
        text-align: center; }
      #section_book .contact_type .contact_type_inner:first-child:before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: #000;
        z-index: 1; }
  #section_book .notes {
    margin: 22px 0 0;
    position: relative; }
    #section_book .notes table {
      width: 100%; }
      #section_book .notes table tbody {
        width: 100%; }
        #section_book .notes table tbody tr {
          width: 100%;
          font-size: 13px;
          color: #fff;
          line-height: 1.5;
          word-break: break-all;
          position: relative;
          text-align: left;
          margin-bottom: 15px;
          display: table; }
          #section_book .notes table tbody tr th {
            display: table-cell;
            width: 18%; }
          #section_book .notes table tbody tr td {
            display: table-cell;
            width: 82%; }
            #section_book .notes table tbody tr td span {
              display: block;
              margin-bottom: 5px; }

/* ==============

     footer

============== */
#footer {
  width: 100%;
  height: auto;
  position: relative; }
  #footer #footerInner {
    padding: 98px 0; }
    #footer #footerInner #footerLogo {
      text-align: center;
      margin: 0 0 28px; }
    #footer #footerInner .footerTxt {
      text-align: center;
      font-size: 13px;
      margin: 0 0 20px;
      text-decoration: none;
      line-height: 1.5; }
      #footer #footerInner .footerTxt span {
        font-size: 18px; }
    #footer #footerInner .toCompanyInfo {
      text-align: center;
      position: relative;
      display: table;
      left: 10px;
      right: 0;
      margin: 28px auto 0;
      font-size: 15px;
      text-decoration: none; }
      #footer #footerInner .toCompanyInfo:before {
        content: "";
        width: 11px;
        height: 10px;
        position: absolute;
        top: 0;
        left: -19px;
        bottom: 0;
        margin: auto;
        background: url(/assets/img/footer/icon1.svg);
        background-size: 11px 10px;
        background-position: center center;
        background-repeat: no-repeat; }
      #footer #footerInner .toCompanyInfo:hover {
        -webkit-transition-duration: 0.8s;
        -ms-transition-duration: 0.8s;
        -moz-transition-duration: 0.8s;
        -o-transition-duration: 0.8s;
        transition-duration: 0.8s;
        -webkit-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
        -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        opacity: .8; }
    #footer #footerInner .forSp {
      display: none; }
    #footer #footerInner .copy {
      margin: 38px 0 0;
      text-align: center;
      font-size: 11px; }
  #footer #toPageTop {
    position: absolute;
    right: 40px;
    bottom: 0; }
    #footer #toPageTop a {
      padding: 0 0 70px;
      display: block;
      font-size: 13px;
      text-decoration: none;
      position: relative; }
      #footer #toPageTop a:before {
        content: "";
        width: 1px;
        height: 60px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: #000; }
      #footer #toPageTop a:hover:hover {
        -webkit-transition-duration: 0.8s;
        -ms-transition-duration: 0.8s;
        -moz-transition-duration: 0.8s;
        -o-transition-duration: 0.8s;
        transition-duration: 0.8s;
        -webkit-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -o-transition-delay: 0s;
        transition-delay: 0s;
        -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        opacity: .8; }
      #footer #toPageTop a:hover:before {
        -webkit-animation-duration: .96s;
        -ms-animation-duration: .96s;
        -moz-animation-duration: .96s;
        -o-animation-duration: .96s;
        animation-duration: .96s;
        -webkit-animation-delay: .1s;
        -ms-animation-delay: .1s;
        -moz-animation-delay: .1s;
        -o-animation-delay: .1s;
        animation-delay: .1s;
        -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
        -webkit-animation-name: toPageTopIconAnimation;
        -moz-animation-name: toPageTopIconAnimation;
        -ms-animation-name: toPageTopIconAnimation;
        animation-name: toPageTopIconAnimation; }

@media only screen and (max-width: 769px) {
  .page.nonVideo .main-content._on {
    -webkit-transform: scale(1.025);
    -moz-transform: scale(1.025);
    -ms-transform: scale(1.025);
    transform: scale(1.025); }
  .page .main-content._on {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  .page .main-content {
    min-width: 320px; }
    .page .main-content._on {
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -ms-transition-delay: 0s;
      transition-delay: 0s;
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -ms-filter: blur(0px);
      filter: blur(0px); }
  .page .main-content > #main-content_inner {
    min-width: 320px; }
  /* ============

  menu contents area

  ============ */
  #menu {
    width: 100%;
    height: 100%; }
    #menu .menu_close_layer {
      display: none; }
    #menu #menuLayer {
      width: 100%;
      height: 100%; }
    #menu .contents_underlay {
      -webkit-transform: scaleX(0);
      -moz-transform: scaleX(0);
      -ms-transform: scaleX(0);
      transform: scaleX(0);
      opacity: .8; }
    #menu .menuInner > .menuContentsWrap {
      padding-top: 19.34vh;
      padding-left: 40px; }
    #menu .menuInner > #menuClose {
      width: 50px;
      height: 50px;
      top: 40px;
      right: 30px;
      z-index: 9100; }
      #menu .menuInner > #menuClose a {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1; }
      #menu .menuInner > #menuClose > a > span {
        width: 26px;
        height: 26px; }
        #menu .menuInner > #menuClose > a > span i {
          width: 26px;
          height: 26px; }
    #menu .menuContentsWrap > #menuLst li {
      margin: 0 0 4.54vh;
      opacity: 0; }
      #menu .menuContentsWrap > #menuLst li a {
        display: block;
        text-decoration: none; }
      #menu .menuContentsWrap > #menuLst li span {
        display: block; }
      #menu .menuContentsWrap > #menuLst li .menuTxt .menuTxtFront {
        display: none; }
      #menu .menuContentsWrap > #menuLst li .menuTxt .menuTxtBack {
        font-size: 2.8vh;
        opacity: 1; }
      #menu .menuContentsWrap > #menuLst li .menuTxtSub {
        opacity: .5;
        font-size: 1.74vh;
        margin: 1.87vh 0 0; }
      #menu .menuContentsWrap > #menuLst li:last-child {
        margin: 0; }
    #menu .menuContentsWrap > #insta {
      margin: 6vh 0 0;
      opacity: 0; }
      #menu .menuContentsWrap > #insta a {
        width: 100%;
        display: block;
        text-decoration: none;
        -webkit-transition-duration: .5s;
        -moz-transition-duration: .5s;
        -ms-transition-duration: .5s;
        transition-duration: .5s; }
        #menu .menuContentsWrap > #insta a:hover {
          opacity: 1; }
      #menu .menuContentsWrap > #insta span {
        display: inline-block; }
      #menu .menuContentsWrap > #insta .instaTxt {
        width: 90%;
        font-size: 13px;
        vertical-align: middle; }
    #menu .menuContentsWrap > #lang {
      margin: 5.34vh 0 0;
      opacity: 0; }
      #menu .menuContentsWrap > #lang > li {
        font-size: 15px;
        opacity: .5;
        display: inline-block;
        margin-right: 28px; }
        #menu .menuContentsWrap > #lang > li a {
          display: inline-block;
          text-decoration: none; }
        #menu .menuContentsWrap > #lang > li._on {
          opacity: 1; }
        #menu .menuContentsWrap > #lang > li:last-child {
          margin-right: 0px; }
    #menu .menuContentsWrap > #booking {
      width: 100%;
      height: 14.67vh;
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: 0; }
      #menu .menuContentsWrap > #booking .bookingTxt {
        width: 100%;
        height: 100%;
        margin: auto;
        display: table; }
        #menu .menuContentsWrap > #booking .bookingTxt > span {
          font-size: 15px; }
      #menu .menuContentsWrap > #booking:hover .hover_animation_cover {
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0); }
    #menu._in #booking .def_cover {
      -webkit-transition-duration: 1.1s;
      -ms-transition-duration: 1.1s;
      -moz-transition-duration: 1.1s;
      -o-transition-duration: 1.1s;
      transition-duration: 1.1s;
      -webkit-transition-delay: 1.4s;
      -ms-transition-delay: 1.4s;
      -moz-transition-delay: 1.4s;
      -o-transition-delay: 1.4s;
      transition-delay: 1.4s;
      -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
      -ms-transform: scaleX(1);
      transform: scaleX(1); }
  .block {
    width: 100%;
    padding: 0 30px;
    position: relative; }
  #header {
    width: 100%;
    height: auto;
    position: relative;
    padding: 0px 30px 40px;
    margin: auto; }
  #header > #header_logo {
    width: 40px;
    height: 86px;
    position: relative;
    top: 0;
    left: 10px;
    right: 0px;
    margin: auto; }
    #header > #header_logo #header_logo_inner {
      -webkit-transform-origin: top left;
      -moz-transform-origin: top left;
      -ms-transform-origin: top left;
      transform-origin: top left;
      -webkit-transform: scale(0.75);
      -moz-transform: scale(0.75);
      -ms-transform: scale(0.75);
      transform: scale(0.75); }
  #header > #header_page_lead {
    position: relative;
    top: 50px;
    left: 0px; }
    #header > #header_page_lead .page_lead {
      font-size: 13px; }
    #header > #header_page_lead .page_lead_en {
      opacity: .5;
      font-size: 10px;
      margin: 7px 0 0;
      display: block; }
  .page_lead_detail {
    display: none; }
  .b2 {
    padding: 0 !important; }
  #menuTrigger {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 40px;
    right: 30px; }
    #menuTrigger a {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1; }
    #menuTrigger > a > span .line_top {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      background-color: #fff; }
    #menuTrigger > a > span .line_middle {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #fff; }
    #menuTrigger > a > span .line_bottom {
      width: 50%;
      height: 1px;
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: #fff; }
    #menuTrigger .trigger_bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000; }
    #menuTrigger._on .line_top {
      -webkit-animation-name: navTriggerHoverAnimation_on;
      -ms-animation-name: navTriggerHoverAnimation_on;
      -moz-animation-name: navTriggerHoverAnimation_on;
      -o-animation-name: navTriggerHoverAnimation_on;
      animation-name: navTriggerHoverAnimation_on;
      -webkit-animation-duration: 0.31s;
      -ms-animation-duration: 0.31s;
      -moz-animation-duration: 0.31s;
      -o-animation-duration: 0.31s;
      animation-duration: 0.31s;
      -webkit-animation-delay: 0s;
      -ms-animation-delay: 0s;
      -moz-animation-delay: 0s;
      -o-animation-delay: 0s;
      animation-delay: 0s;
      -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-animation-iteration-count: 1;
      -ms-animation-iteration-count: 1;
      -moz-animation-iteration-count: 1;
      -o-animation-iteration-count: 1;
      animation-iteration-count: 1;
      -webkit-animation-direction: normal;
      -ms-animation-direction: normal;
      -moz-animation-direction: normal;
      -o-animation-direction: normal;
      animation-direction: normal;
      -webkit-animation-fill-mode: both;
      -ms-animation-fill-mode: both;
      -moz-animation-fill-mode: both;
      -o-animation-fill-mode: both;
      animation-fill-mode: both; }
    #menuTrigger._on .line_middle {
      -webkit-animation-name: navTriggerHoverAnimation_on;
      -ms-animation-name: navTriggerHoverAnimation_on;
      -moz-animation-name: navTriggerHoverAnimation_on;
      -o-animation-name: navTriggerHoverAnimation_on;
      animation-name: navTriggerHoverAnimation_on;
      -webkit-animation-duration: 0.31s;
      -ms-animation-duration: 0.31s;
      -moz-animation-duration: 0.31s;
      -o-animation-duration: 0.31s;
      animation-duration: 0.31s;
      -webkit-animation-delay: 0.1s;
      -ms-animation-delay: 0.1s;
      -moz-animation-delay: 0.1s;
      -o-animation-delay: 0.1s;
      animation-delay: 0.1s;
      -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-animation-iteration-count: 1;
      -ms-animation-iteration-count: 1;
      -moz-animation-iteration-count: 1;
      -o-animation-iteration-count: 1;
      animation-iteration-count: 1;
      -webkit-animation-direction: normal;
      -ms-animation-direction: normal;
      -moz-animation-direction: normal;
      -o-animation-direction: normal;
      animation-direction: normal;
      -webkit-animation-fill-mode: both;
      -ms-animation-fill-mode: both;
      -moz-animation-fill-mode: both;
      -o-animation-fill-mode: both;
      animation-fill-mode: both; }
    #menuTrigger._on .line_bottom {
      -webkit-animation-name: navTriggerHoverAnimation_on;
      -ms-animation-name: navTriggerHoverAnimation_on;
      -moz-animation-name: navTriggerHoverAnimation_on;
      -o-animation-name: navTriggerHoverAnimation_on;
      animation-name: navTriggerHoverAnimation_on;
      -webkit-animation-duration: 0.31s;
      -ms-animation-duration: 0.31s;
      -moz-animation-duration: 0.31s;
      -o-animation-duration: 0.31s;
      animation-duration: 0.31s;
      -webkit-animation-delay: 0.2s;
      -ms-animation-delay: 0.2s;
      -moz-animation-delay: 0.2s;
      -o-animation-delay: 0.2s;
      animation-delay: 0.2s;
      -webkit-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -moz-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -ms-animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-animation-iteration-count: 1;
      -ms-animation-iteration-count: 1;
      -moz-animation-iteration-count: 1;
      -o-animation-iteration-count: 1;
      animation-iteration-count: 1;
      -webkit-animation-direction: normal;
      -ms-animation-direction: normal;
      -moz-animation-direction: normal;
      -o-animation-direction: normal;
      animation-direction: normal;
      -webkit-animation-fill-mode: both;
      -ms-animation-fill-mode: both;
      -moz-animation-fill-mode: both;
      -o-animation-fill-mode: both;
      animation-fill-mode: both; }
  /* ==============

     footer

  ============== */
  #footer {
    width: 100%;
    height: auto;
    position: relative; }
    #footer #footerInner {
      padding: 48px 0; }
      #footer #footerInner #footerLogo {
        text-align: center;
        margin: 0 0 22px; }
      #footer #footerInner .footerTxt {
        text-align: center;
        font-size: 10px;
        margin: 0 0 10px; }
        #footer #footerInner .footerTxt span {
          font-size: 10px; }
      #footer #footerInner .toCompanyInfo {
        text-align: center;
        position: relative;
        display: table;
        margin: 19px auto 0;
        font-size: 10px;
        text-decoration: none; }
        #footer #footerInner .toCompanyInfo:before {
          content: "";
          width: 9px;
          height: 8px;
          position: absolute;
          top: 0;
          left: -14px;
          bottom: 0;
          margin: auto;
          background-size: 9px 8px; }
        #footer #footerInner .toCompanyInfo:hover {
          opacity: 1; }
      #footer #footerInner .forPc {
        display: none; }
      #footer #footerInner .forSp {
        display: table; }
      #footer #footerInner .copy {
        margin: 31px 0 0;
        text-align: center;
        font-size: 8px;
        -webkit-transform: scale(0.9);
        -webkit-transform-origin: center; }
    #footer #toPageTop {
      display: none; }
  /* ==============

      mvArea_r

  ============== */
  #mvArea_r_inner {
    width: 100%;
    height: 270px;
    position: relative; }
    #mvArea_r_inner > .mvWrap {
      width: 100%;
      height: 270px;
      padding-left: 0px;
      box-sizing: border-box;
      display: block; }
      #mvArea_r_inner > .mvWrap .contents_overlay {
        margin-left: 0px; }
      #mvArea_r_inner > .mvWrap .mvWrapInner {
        margin: 0 0 0 0; }
      #mvArea_r_inner > .mvWrap .mv {
        width: 100%;
        height: 270px;
        display: block;
        background-size: cover !important;
        background-position: center center !important;
        background-repeat: no-repeat !important; }
    #mvArea_r_inner > .link {
      display: none; }
  /* ==============

      mvArea_c

  ============== */
  #mvArea_c {
    width: 100%;
    display: block;
    position: relative; }
  #mvArea_c_inner {
    width: 100%;
    height: 240px;
    position: relative; }
    #mvArea_c_inner > .mvWrap {
      width: 100%;
      box-sizing: border-box;
      display: block; }
      #mvArea_c_inner > .mvWrap .mv {
        width: 100%;
        display: block;
        background-size: cover !important;
        background-position: center center !important;
        background-repeat: no-repeat !important; }
  /* ==============

     ご予約

  ============== */
  #section_book {
    width: 100%;
    position: relative; }
    #section_book #section_book_inner {
      width: 100%;
      height: 100%; }
      #section_book #section_book_inner > div {
        width: 100%;
        height: 100%;
        display: block; }
        #section_book #section_book_inner > div .booking_l {
          width: 100%;
          height: 290px;
          display: block;
          vertical-align: top;
          position: relative; }
          #section_book #section_book_inner > div .booking_l .booking_l_img {
            width: 100%;
            height: 100%;
            display: block;
            background: url(/assets/img/common/reservation_img.jpg);
            background-size: cover !important;
            background-position: center center !important;
            background-repeat: no-repeat !important; }
        #section_book #section_book_inner > div .booking_r {
          width: 100%;
          height: 100%;
          display: block;
          vertical-align: top;
          background-color: #000; }
          #section_book #section_book_inner > div .booking_r .booking_r_inner {
            width: 100%;
            height: 100%;
            padding: 30px 30px 30px;
            position: relative;
            box-sizing: border-box; }
            #section_book #section_book_inner > div .booking_r .booking_r_inner .booking_ttl {
              position: relative;
              font-size: 15px;
              color: #fff;
              padding: 0 0 0 30px; }
              #section_book #section_book_inner > div .booking_r .booking_r_inner .booking_ttl .booking_ttl_icon {
                width: 20px;
                height: 2px;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                background-color: #c38118; }
            #section_book #section_book_inner > div .booking_r .booking_r_inner .booking_ttl_sub {
              margin: 28px 0 20px;
              font-size: 13px;
              color: #fff;
              line-height: 1.8; }
    #section_book .contact_type {
      width: 100%;
      position: relative;
      display: table; }
      #section_book .contact_type .contact_type_inner {
        width: 50%;
        position: relative;
        display: table-cell;
        vertical-align: top; }
        #section_book .contact_type .contact_type_inner a {
          width: 100%;
          height: 100%;
          display: block;
          padding: 19px 0 22px;
          background-color: #141414; }
        #section_book .contact_type .contact_type_inner.forSp {
          display: table-cell; }
        #section_book .contact_type .contact_type_inner.forPc {
          display: none; }
        #section_book .contact_type .contact_type_inner > a > span {
          color: #4c4c4c;
          text-align: center;
          font-size: 10px;
          display: block; }
        #section_book .contact_type .contact_type_inner > a > p {
          color: #fff;
          margin: 12px 0 0;
          text-align: center;
          font-size: 15px; }
        #section_book .contact_type .contact_type_inner > a > address {
          color: #fff;
          margin: 10px 0 0;
          font-size: 15px;
          text-align: center; }
        #section_book .contact_type .contact_type_inner:first-child:before {
          content: "";
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #000;
          z-index: 1; }
    #section_book .notes {
      margin: 22px 0 0; }
      #section_book .notes table {
        width: 100%; }
        #section_book .notes table tbody {
          width: 100%; }
          #section_book .notes table tbody tr {
            font-size: 10px;
            line-height: 1.8; }
            #section_book .notes table tbody tr th {
              display: table-cell;
              width: 25%; }
            #section_book .notes table tbody tr td {
              display: table-cell;
              width: 75%; } }
